<template>
  <div>
    <section>
      <Hero>
        <div class="container">
          <HeadText v-if="isMeinHandel" :titletext="'Jederzeit alles im Blick'" :subtext="'Komfortabel, kurzfristig und sicher handeln.'"></HeadText>
          <HeadText v-else :titletext="'Mein Zweitmarkt'" :subtext="'Komfortabel, kurzfristig und sicher handeln.'"></HeadText>
        </div>

        <div class="container mt-5">
          <div class="row justify-content-around">
            <div class="col-sm-4 bg-light mb-4">
              <div v-if="hasNoToken">
                <h2 class="h2-page-title mt-3" :class="{ 'h2-mobile': mobileViewport }">Login</h2>
                <div>
                  <p class="text-left">
                    <b-form @submit="onSubmit">
                      <b-form-group id="input-group-1" label="E-Mail / Login" label-for="input-1">
                        <b-form-input
                            id="input-1"
                            v-model="formdata.username"
                            type="text"
                            required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-2" label="Passwort" label-for="input-2">
                        <b-form-input
                            id="input-2"
                            v-model="formdata.password"
                            type="password"
                            required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group v-if="$store.getters['auth/authStatus'] === 'password-expired'">
                        <b-alert show variant="danger">Ihr Passwort ist abgelaufen. Bitte fordern Sie ein neues Passwort an.</b-alert>
                      </b-form-group>
                      <b-form-group v-else-if="$store.getters['auth/authStatus'] === 'error'">
                        <b-alert show variant="danger">Bitte überprüfen Sie Ihre Eingaben.</b-alert>
                      </b-form-group>
                      <b-form-group id="submit-group-1">
                        <b-button type="submit" variant="default" style="display:block;width:100%">Anmelden</b-button>
                      </b-form-group>
                    </b-form>  
                  </p>
                  <p class="text-center fs-7"><router-link :to="{name: 'ResetPassword', params: {client}}" class="nav-link">Passwort vergessen?</router-link></p>
                </div>
                <div class="mb-5">
                  <p class="text-center">
                    &ndash;&ndash;&ndash;&ndash;&ndash;&ndash; oder &ndash;&ndash;&ndash;&ndash;&ndash;&ndash;
                  </p>
                  <p>Registrieren Sie sich jetzt kostenlos auf <br/><strong>Mein Zweitmarkt</strong></p>
                  <p class="text-center"><router-link class="btn btn-default" :to="{name: 'Registration', params: {client}}">Registrieren</router-link></p>
                </div>
              </div>
              <div v-else>
                <p>Sie sind bereits angemeldet.</p>
              </div>
              <div class="saveSSL">
                <i class="fa fa-lock mr-2"></i> Sichere SSL-Verschlüsselung
              </div>
            </div>
            <div class="col-sm-7">

              <h4 class="mb-4" v-if="isMeinHandel">Melden Sie sich an und nutzen Sie die Vorteile:</h4>
              <h2 class="h2-page-title mb-4" v-else :class="{ 'h2-mobile': mobileViewport }">Melden Sie sich an und nutzen Sie die Vorteile:</h2>

              <div class="blockList text-left">
                <div class="blockIcon">
                  <i class="fa fa-tasks"></i>
                </div>
                <div class="blockText">
                  <h5>Auftragsstatus</h5>
                  <p>
                    Sie können jederzeit verfolgen in welchem Stadium sich Ihr Auftrag gerade befindet.
                  </p>
                </div>
              </div>

              <div class="blockList text-left">
                <div class="blockIcon">
                  <i class="fa fa-credit-card"></i>
                </div>
                <div class="blockText">
                  <h5>Kaufen und Verkaufen</h5>
                  <p>
                    Sie möchten kaufen oder verkaufen? Suchen Sie einfach Ihren Fonds und nutzen Sie unseren
                    Online-Auftrag. Einfach ausfüllen und absenden.
                  </p>
                </div>
              </div>

              <div class="blockList text-left">
                <div class="blockIcon">
                  <i v-if="isMeinHandel" class="fa fa-envelope"></i>
                  <i v-else class="fa fa-envelope-open"></i>
                </div>
                <div class="blockText">
                  <h5>Käufermail</h5>
                  <p>
                    Lassen Sie sich handelstäglich über die neuen Verkaufsangebote informieren.
                  </p>
                </div>
              </div>

              <div class="blockList text-left">
                <div class="blockIcon">
                  <i class="fa fa-eye"></i>
                </div>
                <div class="blockText">
                  <h5>Beobachtungsliste</h5>
                  <p>
                    Beobachten Sie die Entwicklung von interessanten Fonds. Merken Sie sich die Fonds mit nur einem Klick.
                  </p>
                </div>
              </div>

              <div class="blockList text-left">
                <div class="blockIcon">
                  <i class="fa fa-file-alt"></i>
                </div>
                <div class="blockText">
                  <h5>Fondsdokumente</h5>
                  <p>
                    Erhalten Sie aktuelle Dokumente zum Download. Dazu zählen Geschäfts- und Zwischenberichte sowie Prospekte und Mitteilungen der Fonds.
                  </p>
                </div>
              </div>

              <div class="blockList text-left">
                <div class="blockIcon">
                  <i v-if="isMeinHandel" class="fa fa-paper-plane"></i>
                  <i v-else class="fa fa-info-circle"></i>
                </div>
                <div class="blockText">
                  <h5>Aktuelle Nachrichten</h5>
                  <p>
                    Abonnieren Sie die aktuellen Nachrichten zu den Handelsaktivitäten Ihrer ausgewählten Fonds.
                  </p>
                </div>
              </div>

            </div>
          </div>  
        </div>

        <form ref="redirectForm" method="post">
          <input :value="token" type="hidden" name="token">
        </form>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import $ from "jquery";
import router from "../router";
import VueJwtDecode from "vue-jwt-decode";

export default {
  props: {
    href: {
      type: String,
      default: '',
    },
  },
  name: "Login",
  metaInfo() {
    let rel = '';
    let currentUrl = '';
    if(this.client !== 'meinzweitmarkt') {
      rel = 'canonical';
      currentUrl = window.location.href.replace(this.client, 'meinzweitmarkt');
    }
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
      ],
      link: [
        {
          rel: rel,
          href: currentUrl,
        }
      ]
    }
  },
  components: {Hero, HeadText},
  computed: {
    client() {
      return this.$store.getters['clientConfig/client']
    },
    token() {
      return this.$store.getters["auth/getToken"]
    },
    confidentiality() {
      return this.$store.getters["compliance/hasConfidentiality"]
    },
    subsiteDisplayName() {
      return this.isMeinHandel ? 'MeinHandel' : 'MeinZweitmarkt'
    },
    isMeinHandel() {
      return this.$store.getters["getSubsite"] === 'meinhandel';
    },
    hasNoToken() {
      var token = this.$store.getters["auth/getToken"];
      return token === undefined || token === null || token === '';
    },
    mobileViewport() {
      return window.innerWidth < this.$store.getters.getMobileBreakpoint;
    }
  },
  data: () => ({
    formdata: {
      username: null,
      password: null,
    },
    prevRoute: null,
  }),
  mounted: function closeNav () {
    $('#nav-collapse').css('display', 'none');
    this.$store.dispatch('auth/setLogoutState')
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },

    onSubmit: function ( event ) {
      event.preventDefault();

      const formData = {
        username : this.formdata.username,
        password : this.formdata.password,
      }

      let routing = null

      if(this.prevRoute.name) {
        routing = {
          name: this.prevRoute.name
        }
      } else {
        routing = {
          name: 'fondslist'
        }
      }

      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch('auth/login',formData).then( async () => {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const auftragsverwaltung = urlParams.get('a');
        const auftragType = urlParams.get('b');
        const tid = urlParams.get('c');
        const auftragNr = urlParams.get('d');
        const id = urlParams.get('e');
        const setpassword = urlParams.get('sp');
        const registerconfirm = urlParams.get('rc');
        const enforcePasswordChange = this.$store.getters['user/isChangePasswordEnforced'];

        if(this.$store.getters['auth/authStatus'] === 'success') {
          const encoded = this.$store.getters['auth/getToken'];
          const decoded = VueJwtDecode.decode(encoded);

          let subsite = null;

          if(this.$store.getters['getSubsite']) {
            subsite = this.$store.getters['getSubsite'];
          } else {
            subsite = window.location.pathname?.split('/')?.[1];
          }

          await this.$store.dispatch('compliance/getCompliance',decoded.guid).then( () => {

            if(this.confidentiality === true) { // Wenn die Vertraulichkeitszustimmung gültig ist:
              if (enforcePasswordChange) {
                router.push({name: 'ChangePassword', params: {client: subsite}});
                return;
              }

              if (auftragsverwaltung) {
                router.push({name: 'AuftragsVerwaltung', params: {client: subsite}, query: {auftragType, tid, auftragNr, id}});
              } else if (setpassword || registerconfirm) {
                router.push({name: 'fondslist', params: {client: subsite}});
              } else {
                this.$store.dispatch('auth/backToOrigin', routing);
              }

            } else { // und wenn sie nicht gültig ist:
              if (auftragsverwaltung) {
                router.push({name: 'Compliance', params: {client: subsite}, query: {auftragType, tid, auftragNr, id}});
              } else {
                router.push({name: 'Compliance', params: {client: subsite}});
              }
            }
          }, error => {
            console.log( error )
          });
        }
      }, error => {
        console.log( error )
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  created() {
    if ( this.$route.params.loginName ) {
      this.formdata.username = this.$route.params.loginName;  
    }
  },
}
</script>

<style scoped>

</style>